import { publicJob as publicJobUrl } from '#/js/config/api.json';

export default {
    // 获取大厂职位统计数（投递、收藏、面试数）
    getPublicJobStatistics (params = {}) {
        return _request({
            url: publicJobUrl.get_public_job_statistics,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 获取大厂职位列表
    getPublicJobList (params = {}) {
        return _request({
            url: publicJobUrl.get_public_job_list,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 撤回审核
    cancelAudit (publicJobId, params = {}) {
        return _request({
            url: publicJobUrl.cancel_audit.replace(/%p/, publicJobId),
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 取消发布
    cancelPublish (publicJobId, params = {}) {
        return _request({
            url: publicJobUrl.cancel_publish.replace(/%p/, publicJobId),
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 提交审核
    submitAudit (publicJobId, params = {}) {
        return _request({
            url: publicJobUrl.submit_audit.replace(/%p/, publicJobId),
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 发布
    publishPublicJob (publicJobId, params = {}) {
        return _request({
            url: publicJobUrl.publish_public_job.replace(/%p/, publicJobId),
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 创建大厂职位
    createPublicJob (params = {}) {
        return _request({
            url: publicJobUrl.create_public_job,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 编辑大厂职位
    editPublicJob (params = {}) {
        return _request({
            url: publicJobUrl.edit_public_job,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 获取大厂职位编辑详情
    getPublicJobEditInfo (jobId, params = {}) {
        return _request({
            url: publicJobUrl.get_public_job_edit_info.replace(/%p/, jobId),
            method: 'GET',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 获取大厂职位审批列表
    getPublicJobAuditList (params = {}) {
        return _request({
            url: publicJobUrl.get_public_job_audit_list,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 大厂职位审批通过、拒绝
    publicJobAudit (params = {}) {
        return _request({
            url: publicJobUrl.public_job_audit,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 获取大厂职位投递列表
    getPublicJobDeliverList (params = {}) {
        return _request({
            url: publicJobUrl.get_public_job_deliver_list,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 获取大厂职位投递的简历详情
    getDeliverResumeDetail (resumeId, params = {}) {
        return _request({
            url: publicJobUrl.get_deliver_resume_detail.replace(/%p/, resumeId),
            method: 'GET',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 获取大厂职位收藏列表
     getPublicJobCollectList (params = {}) {
        return _request({
            url: publicJobUrl.get_public_job_collect_list,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },

    // 大厂简历的保存
    cocResumeSave(candidateId, resumeData) {
        return _request({
            method: 'POST',
            url: `/My/Candidate/${candidateId}/Edit`,
            data: resumeData
        });
    },

    // 简历的保存
    resumeSave(candidateId, resumeData) {
        return _request({
            method: 'POST',
            url: `/My/Candidate/${candidateId}/Edit`,
            data: resumeData
        });
    },
    
    // 投递简历不通过
    deliverNoPass (deliveryId, params = {}) {
        return _request({
            url: publicJobUrl.deliver_no_pass.replace(/%p/, deliveryId),
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
};
