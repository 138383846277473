<template>
    <el-dialog
        :before-close="handleClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogVisible"
        custom-class="famous-company-dialog"
        title="发布到大厂机会"
        width="826px"
    >
        <div class="main">
            <div class="form-wrap">
                <div class="top-title">
                    <h1>原职位信息</h1>
                    <p></p>
                </div>
                <el-form
                    :model="form"
                    disabled
                    label-width="80px"
                >
                    <el-form-item label="职位名称">
                        <el-input v-model="form.jobName"></el-input>
                    </el-form-item>
                    <el-form-item label="公司名称">
                        <el-input v-model="form.customerName"></el-input>
                    </el-form-item>
                    <el-form-item label="工作地点">
                        <el-input v-model="form.locations"></el-input>
                    </el-form-item>
                    <el-form-item label="经验要求">
                        <el-input v-model="form.experience"></el-input>
                    </el-form-item>
                    <el-form-item label="学历要求">
                        <el-input v-model="form.degree"></el-input>
                    </el-form-item>
                    <el-form-item label="薪资范围">
                        <el-input v-model="form.salary"></el-input>
                    </el-form-item>
                    <el-form-item label="职位描述">
                        <el-input
                            :rows="4"
                            type="textarea"
                            v-model="form.description"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div class="form-wrap">
                <div class="top-title">
                    <h1>发布到大厂机会职位信息</h1>
                    <p>请确保职位信息中不透露任何雇主公司信息</p>
                </div>
                <el-form
                    :model="publishForm"
                    :rules="publishRule"
                    label-width="80px"
                    ref="publishForm"
                >
                    <el-form-item
                        label="职位名称"
                        prop="jobName"
                    >
                        <el-input
                            maxlength="128"
                            placeholder="职位名称"
                            v-model="publishForm.jobName"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="公司名称"
                        prop="customerName"
                    >
                        <el-input
                            maxlength="128"
                            placeholder="请不要直接写公司名称"
                            v-model="publishForm.customerName"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="工作地点">
                        <el-input
                            disabled
                            v-model="publishForm.locations"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="经验要求">
                        <el-input
                            disabled
                            v-model="publishForm.experience"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="学历要求">
                        <el-input
                            disabled
                            v-model="publishForm.degree"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="薪资范围">
                        <div class="salary-wrap">
                            <el-form-item prop="minSalary">
                                <el-input
                                    placeholder="最低月薪"
                                    v-model.number="publishForm.minSalary"
                                >
                                    <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="maxSalary">
                                <el-input
                                    placeholder="最高月薪"
                                    v-model.number="publishForm.maxSalary"
                                >
                                    <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                                </el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <el-form-item
                        label="职位描述"
                        prop="description"
                    >
                        <el-input
                            :rows="4"
                            maxlength="8000"
                            placeholder="主要负责......"
                            type="textarea"
                            v-model="publishForm.description"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="select-member">
            <p class="select-tip">指定其他人可以跟进该职位的投递简历</p>

            <div class="employees">
                <el-popover
                    popper-class="team-member-popover static-team-member-popover"
                    placement="bottom-end"
                    width="700"
                    trigger="click"
                    v-model="teamMemberPopoverVisible"
                >
                    <!-- :date="dateRange" -->
                    <team-member-select
                        ref="teamSelect"
                        :isAllMembers="true"
                        :isShowDimission="false"
                        :isHistoryData="false"
                        :filterLoginUser="true"
                        :isFamousCompanyOpportunity="true"
                        :visible="teamMemberPopoverVisible"
                        :selected="userIds"
                        @item-select-cb="employeeSelect"
                    ></team-member-select>
                    <div slot="reference" class="employee-select-text">
                        <span class="text" v-if="selectedMembersText">{{ selectedMembersText }}</span>
                        <span class="placeholder" v-else>请选择成员</span>
                    </div>
                </el-popover>
            </div>
        </div>

        <span
            class="dialog-footer"
            slot="footer"
        >
            <el-button @click="handleClose">取消</el-button>
            <el-button
                :loading="confirmLoading"
                @click="submitForm('publishForm')"
                type="primary"
            >提 交</el-button>
        </span>
    </el-dialog>
</template>

<script>
import publicJobService from '#/js/service/publicJobService.js';
import { createLogger } from 'vuex';
import {
    workExperience as workExperienceJson,
    degree as degreeJson
} from '#/js/config/searchField.json';
import { getCityTextById } from '#/js/util/provinceCity.js';
import moment from "moment";
import TeamMemberSelect from '#/component/common/team-member-select.vue';


export default {
    name: 'famous-company-dialog',
    components: {
        TeamMemberSelect,
    },
    data () {
        return {
            teamMemberPopoverVisible: false,
            selectedMembersText: '',
            userIds: [],
            startDate: "",
            endDate: "",
            // dateRange: [],

            dialogVisible: false,
            confirmLoading: false,
            form: {
                jobName: '',
                customerName: '',

                locations: '',
                experience: '',
                degree: '',

                salary: '',
                description: '',
            },
            publishForm: {
                jobName: '',
                customerName: '',

                locations: '',
                experience: '',
                degree: '',

                minSalary: '',
                maxSalary: '',
                description: '',
            },

            publicJobId: '',

            publishRule: {
                jobName: [
                    { required: true, message: '请输入职位名称', trigger: ['blur', 'change'] },
                ],
                customerName: [
                    { required: true, message: '请输入公司名称', trigger: ['blur', 'change'] },
                ],
                // salary: [
                // { required: true, message: '请输入薪资范围', trigger: ['blur', 'change'] },
                //     { validator: this.validateSalary, trigger: ['blur', 'change'] },
                // ],
                minSalary: [
                    { required: true, message: '请填写最低月薪', trigger: ['change'] },
                    { type: 'number', min: 1, max: 999, message: '最低月薪范围是1-999', trigger: 'change' },
                    { type: 'number', validator: this.validateMinSalary, trigger: ['blur'] },
                ],
                maxSalary: [
                    { required: true, message: '请填写最高月薪', trigger: ['change'] },
                    { type: 'number', min: 1, max: 999, message: '最高月薪范围是1-999', trigger: 'change' },
                    { type: 'number', validator: this.validateMaxSalary, trigger: ['blur'] },
                ],
                description: [
                    { required: true, message: '请输入职位描述', trigger: ['blur', 'change'] },
                ],
            },
        };
    },
    created () { },
    mounted () {
        // this.startDate = moment().subtract(0, "months").format("YYYY-MM") + "-01";
        // this.endDate = moment(this.startDate)
        //     .add(1, "month")
        //     .subtract(1, "days")
        //     .format("YYYY-MM-DD");
        // this.dateRange = [this.startDate, this.endDate];
    },
    methods: {
        filterDegree (val) {
            return degreeJson[String(val)];
        },
        filteWorkExperience (val) {
            return workExperienceJson[String(val)];
        },
        filteLocations (val) {
            let locationsText = [];
            if(!val) return '';
            val.forEach(el => {
                locationsText.push(getCityTextById(el));
            });
            return locationsText.join(',');
        },

        // 选择成员
        employeeSelect(userIds, text) {
            this.userIds = userIds;
            this.selectedMembersText = text;
            this.teamMemberPopoverVisible = false;
        },

        show (originalInfo, publicInfo, publicJobId) {
            this.dialogVisible = true;

            this.$nextTick(() => {
                console.log(this.$refs);
                // this.$refs.teamSelect.getHistoryTeamData(false);
                this.$refs.teamSelect.getTeamMembers();
            })

            this.publicJobId = publicJobId;

            this.form = {
                jobName: originalInfo.name || originalInfo.jobName,
                customerName: originalInfo.customerName,

                locations: originalInfo.location || this.filteLocations(originalInfo.locationIds),
                experience: originalInfo.yearOfExperience || this.filteWorkExperience(originalInfo.minYearOfExperience),
                degree: originalInfo.degree || this.filterDegree(originalInfo.minDegree),

                salary: originalInfo.salary || `${originalInfo.minSalary}K - ${originalInfo.maxSalary}K`,
                description: originalInfo.description,
            };

            this.publishForm = {
                jobId: originalInfo.id, // 源职位id
                jobName: publicJobId ? publicInfo.jobName : originalInfo.name,
                customerName: publicJobId ? publicInfo.customerName : '某大厂',

                locations: publicJobId ? this.filteLocations(publicInfo.locationIds) : (originalInfo.location || this.filteLocations(originalInfo.locationIds)),
                experience: publicJobId ? this.filteWorkExperience(publicInfo.minYearOfExperience) : (originalInfo.yearOfExperience || this.filteWorkExperience(originalInfo.minYearOfExperience)),
                degree: publicJobId ? this.filterDegree(publicInfo.minDegree) : (originalInfo.degree || this.filterDegree(originalInfo.minDegree)),

                minSalary: publicJobId ? publicInfo.minSalary : originalInfo.minSalary || '',
                maxSalary: publicJobId ? publicInfo.maxSalary : originalInfo.maxSalary || '',
                description: publicJobId ? publicInfo.description : originalInfo.description,
            };

            // 获取选择的成员id和显示文本
            this.userIds = [];
            this.selectedMembersText = '';
            this.userIds = publicInfo?.followUpPeople.map(el => {
                return el.userId;
            });
            if(publicInfo?.followUpPeople?.length > 0) {
                publicInfo?.followUpPeople.map(el => {
                    if(this.selectedMembersText == '') {
                        this.selectedMembersText = el.realName + '@' + el.nickname;
                    }else {
                        this.selectedMembersText = this.selectedMembersText + '，' + el.realName + '@' + el.nickname;
                    }
                })
            } else {
                this.selectedMembersText = '';
            }
        },
        validateMinSalary (rule, value, callback) {
            if (this.publishForm.minSalary && this.publishForm.maxSalary && this.publishForm.minSalary > this.publishForm.maxSalary) {
                callback(new Error(`最小值要小于最大值`));
            } else {
                setTimeout(() => {
                    if (this.publishForm.maxSalary) {
                        this.$refs.publishForm?.validateField('maxSalary');
                    }
                });
                callback();
            }
        },
        validateMaxSalary (rule, value, callback) {
            if (this.publishForm.minSalary && this.publishForm.maxSalary && this.publishForm.minSalary > this.publishForm.maxSalary) {
                callback(new Error(`最小值要小于最大值`));
            } else {
                setTimeout(() => {
                    if (this.publishForm.minSalary) {
                        this.$refs.publishForm.validateField('minSalary');
                    }
                });
                callback();
            }
        },
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const params = {
                        ...this.publishForm,
                        followUpPeople: [],
                    };
                    console.log(this.userIds);
                    if(this.userIds?.length > 0) {
                        params.followUpPeople = this.userIds;
                    }

                    console.log(params);
                    

                    this.confirmLoading = true;

                    if (this.publicJobId) { // 编辑
                        params.id = this.publicJobId;
                        publicJobService.editPublicJob(params)
                            .then(res => {
                                shortTips(`编辑成功，职位将在审核通过后发布`);
                                this.handleClose();
                                this.$emit('editSuccess');
                            })
                            .finally(() => {
                                this.confirmLoading = false;
                            });
                    } else { // 创建
                        publicJobService.createPublicJob(params)
                            .then(res => {
                                shortTips(`提交成功，职位将在审核通过后发布`);
                                this.handleClose();
                                setTimeout(() => {
                                    this.$emit('refresh');
                                }, 500);
                            })
                            .finally(() => {
                                this.confirmLoading = false;
                            });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm (formName) {
            this.$refs[formName].resetFields();
        },
        handleClose () {
            this.resetForm('publishForm');
            this.dialogVisible = false;
        },
        
    },
}
</script>

<style lang="scss" scope>
.famous-company-dialog {
    .el-dialog__body {
        padding: 0;
        .main {
            border-bottom: 1px solid #eee;
            display: flex;
            .form-wrap {
                flex: 1;
                box-sizing: border-box;
                padding: 25px 20px;
                &:first-child {
                    border-right: 1px solid #eee;
                }

                .top-title {
                    height: 40px;
                    margin-bottom: 16px;
                    > h1 {
                        &::before {
                            display: inline-block;
                            background: $primary;
                            content: '';
                            width: 4px;
                            height: 20px;
                            margin-right: 13px;
                            vertical-align: middle;
                        }
                        color: #333333;
                        font-size: 16px;
                        margin: 0;
                    }
                    > p {
                        color: #e24e3a;
                        font-size: 14px;
                        padding-left: 18px;
                    }
                }

                .salary-wrap {
                    display: flex;
                    justify-content: space-between;
                    > div {
                        flex: 1;
                        max-width: 48%;
                    }
                }
            }
        }

        .select-member {
            .select-tip {
                margin: 20px;
                text-align: center;
                color: #666666;
                font-size: 14px;
            }

            .employees {
                position: relative;
                width: 480px;
                height: 40px;
                line-height: 40px;
                padding: 0 20px;
                margin: 0 auto;
                border: 1px solid #d3d3d3;
                border-radius: 4px;

                .employee-select-text {
                    display: flex;
                    align-items: center;

                    .text {
                        flex: 1;
                        flex-grow: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .placeholder {
                        font-size: 14px;
                        color: #A3AFB7;
                    }
                }

                .employee-select-arrow {
                    margin-right: 0;
                }
            }
        }
    }

    .dialog-footer {
        display: block;
        text-align: center;
        padding-top: 39px;
    }
}
</style>